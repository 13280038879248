import React, { useState, useEffect, useCallback } from "react";
import Confetti from "react-confetti";
import "./App.css";

const Minesweeper = () => {
  const [width, setWidth] = useState(10);
  const [height, setHeight] = useState(10);
  const [mines, setMines] = useState(10);
  const [grid, setGrid] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [flagged, setFlagged] = useState(0);
  const [firstClick, setFirstClick] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  const [isMineTriggered, setIsMineTriggered] = useState(false);
  const [touchTimer, setTouchTimer] = useState(null);

  const handleTouchStart = (x, y) => {
    const timer = setTimeout(() => {
      flagCell(x, y); // Place a flag if held
    }, 500); // Adjust the time (in milliseconds) for how long to hold for a flag
    setTouchTimer(timer);
  };

  const handleTouchEnd = (x, y) => {
    if (touchTimer) {
      clearTimeout(touchTimer);
      setTouchTimer(null);
      revealCell(x, y); // Clear the cell if it’s a short tap
    }
  };

  const initializeGrid = useCallback(() => {
    const newGrid = [];
    for (let i = 0; i < height; i++) {
      newGrid.push([]);
      for (let j = 0; j < width; j++) {
        newGrid[i].push({ value: 0, revealed: false, flagged: false });
      }
    }
    setGrid(newGrid);
  }, [height, width]);

  const resetGame = () => {
    setGameOver(false);
    setIsMineTriggered(false);
    setShowConfetti(false);
    setShowCongrats(false);
    setFlagged(0);
    setFirstClick(true);
    initializeGrid();
  };

  useEffect(() => {
    initializeGrid();
  }, [initializeGrid]);

  const setGridSize = (size) => {
    switch (size) {
      case "small":
        setWidth(8);
        setHeight(8);
        setMines(10);
        break;
      case "medium":
        setWidth(12);
        setHeight(12);
        setMines(20);
        break;
      case "large":
        setWidth(20); // Wider grid
        setHeight(12); // Shorter height
        setMines(40); // Keep mine count similar for difficulty
        break;
      default:
        setWidth(10);
        setHeight(10);
        setMines(10);
    }
    setGameOver(false);
    setShowConfetti(false);
    setShowCongrats(false);
    setFlagged(0);
    setFirstClick(true);
    initializeGrid();
  };

  const updateValues = (grid) => {
    for (let i = 0; i < height; i++) {
      for (let j = 0; j < width; j++) {
        if (grid[i][j].value === -1) continue; // Skip cells with mines
        let count = 0;
        for (let x = -1; x <= 1; x++) {
          for (let y = -1; y <= 1; y++) {
            if (x === 0 && y === 0) continue; // Skip the current cell
            const newX = j + x;
            const newY = i + y;
            if (newX >= 0 && newX < width && newY >= 0 && newY < height) {
              if (grid[newY][newX].value === -1) {
                count++;
              }
            }
          }
        }
        grid[i][j].value = count;
      }
    }
  };

  const placeMines = (x, y) => {
    const newGrid = [...grid];
    let minesPlaced = 0;
    while (minesPlaced < mines) {
      const newX = Math.floor(Math.random() * width);
      const newY = Math.floor(Math.random() * height);
      if (newX === x && newY === y) continue; // Avoid placing mine at first click
      if (newGrid[newY][newX].value === 0) {
        newGrid[newY][newX].value = -1;
        minesPlaced++;
      }
    }
    updateValues(newGrid);
    setGrid(newGrid);
  };

  const countAdjacentFlags = (x, y) => {
    let count = 0;
    for (let dx = -1; dx <= 1; dx++) {
      for (let dy = -1; dy <= 1; dy++) {
        if (dx === 0 && dy === 0) continue; // Skip the center cell
        const nx = x + dx;
        const ny = y + dy;
        if (
          nx >= 0 &&
          nx < width &&
          ny >= 0 &&
          ny < height &&
          grid[ny][nx].flagged
        ) {
          count++;
        }
      }
    }
    return count;
  };

  const revealAdjacentSquares = (x, y) => {
    const newGrid = [...grid];
    for (let dx = -1; dx <= 1; dx++) {
      for (let dy = -1; dy <= 1; dy++) {
        if (dx === 0 && dy === 0) continue;
        const nx = x + dx;
        const ny = y + dy;
        if (
          nx >= 0 &&
          nx < width &&
          ny >= 0 &&
          ny < height &&
          !newGrid[ny][nx].revealed &&
          !newGrid[ny][nx].flagged
        ) {
          revealCell(nx, ny);
        }
      }
    }
    setGrid(newGrid);
  };

  const revealAllMines = (grid) => {
    for (let i = 0; i < height; i++) {
      for (let j = 0; j < width; j++) {
        if (grid[i][j].value === -1) {
          grid[i][j].revealed = true;
        }
      }
    }
  };

  const revealCell = (x, y, bothButtons = false) => {
    if (gameOver) {
      return;
    }

    const newGrid = [...grid];
    if (firstClick) {
      placeMines(x, y);
      setFirstClick(false);
    }

    if (newGrid[y][x].flagged) {
      return;
    }

    if (newGrid[y][x].value === -1) {
      // If a mine is clicked, set game over and reveal all mines
      setGameOver(true);
      setIsMineTriggered(true); // Set to true if a mine is hit
      revealAllMines(newGrid);
    } else if (
      bothButtons &&
      newGrid[y][x].revealed &&
      newGrid[y][x].value > 0
    ) {
      const flaggedCount = countAdjacentFlags(x, y);
      if (flaggedCount === newGrid[y][x].value) {
        revealAdjacentSquares(x, y);
      }
    } else {
      newGrid[y][x].revealed = true;
      if (newGrid[y][x].value === 0) {
        revealNeighbors(x, y, newGrid);
      }
    }

    setGrid(newGrid);
    checkWin();
  };

  const checkWin = () => {
    // Check if all non-mine cells are revealed
    const nonMineCellsRevealed =
      grid.flat().filter((cell) => !cell.revealed && cell.value !== -1)
        .length === 0;

    if (nonMineCellsRevealed) {
      setGameOver(true);
      setShowCongrats(true);
      setShowConfetti(true); // This triggers the confetti display
    }
  };

  const revealNeighbors = (x, y, grid) => {
    for (let i = -1; i <= 1; i++) {
      for (let j = -1; j <= 1; j++) {
        if (i === 0 && j === 0) {
          continue;
        }
        const newX = x + i;
        const newY = y + j;
        if (newX >= 0 && newX < width && newY >= 0 && newY < height) {
          if (!grid[newY][newX].revealed) {
            grid[newY][newX].revealed = true;
            if (grid[newY][newX].value === 0) {
              revealNeighbors(newX, newY, grid);
            }
          }
        }
      }
    }
  };

  const flagCell = (x, y) => {
    if (gameOver) {
      return;
    }
    if (grid[y][x].revealed) {
      return;
    }
    grid[y][x].flagged = !grid[y][x].flagged;
    setFlagged(flagged + (grid[y][x].flagged ? 1 : -1));
    setGrid([...grid]);
  };

  return (
    <div className="game-wrapper" onContextMenu={(e) => e.preventDefault()}>
      <div className="game-container text-center">
        {showConfetti && <Confetti />}

        <h1 className="my-3">
          🚩 💣 🤯
          <br />
          <br />
          Minesweeper
        </h1>

        <div className="d-flex align-items-center justify-content-center mb-3">
          <button
            className="btn btn-primary mx-1"
            onClick={() => setGridSize("small")}
          >
            Small 🐜
          </button>
          <button
            className="btn btn-primary mx-1"
            onClick={() => setGridSize("medium")}
          >
            Medium 🐈
          </button>
          <button
            className="btn btn-primary mx-1"
            onClick={() => setGridSize("large")}
          >
            Large 🐘
          </button>
        </div>

        <div className="game-content d-flex justify-content-center">
          <div className="grid-wrapper">
            <div className="grid-container">
              <table>
                <tbody>
                  {grid.map((row, y) => (
                    <tr key={y}>
                      {row.map((cell, x) => (
                        <td key={x}>
                          <button
                            className={`box ${
                              cell.revealed ? "revealed" : ""
                            } ${cell.flagged ? "flagged" : ""}`}
                            data-value={
                              cell.revealed && cell.value > 0 ? cell.value : ""
                            }
                            onClick={() => revealCell(x, y)}
                            onMouseDown={(e) => {
                              if (e.buttons === 3) revealCell(x, y, true); // Both mouse buttons
                            }}
                            onContextMenu={(e) => {
                              e.preventDefault();
                              flagCell(x, y);
                            }}
                            onTouchStart={() => handleTouchStart(x, y)}
                            onTouchEnd={() => handleTouchEnd(x, y)}
                          >
                            {cell.revealed ? (
                              cell.value === -1 ? (
                                <span role="img" aria-label="bomb">
                                  💣
                                </span>
                              ) : cell.value > 0 ? (
                                cell.value
                              ) : (
                                ""
                              )
                            ) : cell.flagged ? (
                              <span role="img" aria-label="flag">
                                🚩
                              </span>
                            ) : (
                              ""
                            )}
                          </button>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="mt-4">
                <p>🚩 Flagged : {flagged}</p>
                <p>
                  {gameOver
                    ? "Game Over!"
                    : `💣 Remaining : ${mines - flagged}`}
                </p>
              </div>
              {isMineTriggered && (
                <div
                  className="game-overlay game-over-overlay"
                  onClick={resetGame}
                >
                  <h2>💣💥Game Over💥💣</h2>
                  <p>Play Again?</p>
                </div>
              )}

              {showCongrats && (
                <div
                  className="game-overlay congrats-message"
                  onClick={resetGame}
                >
                  <h2>Congratulations!</h2>
                  <p>Play Again?</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Bottom ad container */}
        <div className="bottom-ad">
          {/* Google Ad code goes here */}
          <p>Bottom Ad</p>
        </div>
      </div>
    </div>
  );
};

export default Minesweeper;
